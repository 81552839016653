body {
  background-image: url('./assets/background.png');
  background-size: cover;
  background-position: 20% center;
  background-repeat: no-repeat;
  background-color: #12272d;
  color: #fff;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body, #root {
  width: 100vw;
  height: 100vh;
}

h1 {
  font-family: 'Times New Roman';
  font-size: 32px;
  font-weight: 100;
  line-height: 36px;
  color: #fff;
  margin-top: 24px;
  margin-bottom: 54px;
}

a {
  color: #fff;
}

a:visited {
  color: #fff;
}

.root {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.buttons {
  display: flex;
}

.icon-store {
  width: auto;
  height: 56px;
}

.icon-store + .icon-store {
  margin-left: 34px;
}

.footer-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.1px;
  position: absolute;
  bottom: 30px;
  text-align: center;
  left: 0;
  right: 0;
}


@media (min-width: 576px) {
  body {
    background-position: 10% center;
  }
}

@media (min-width: 768px) {
  body {
    background-position: 0% center;
  }
}

@media (min-width: 992px) {
  body {
    background-position: center;
  }
}

@media (min-width: 1200px) {
  body {
    background-position: center;
  }
}
